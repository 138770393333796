/* eslint-disable no-underscore-dangle */
import jsonDiff from './lib/jsonDiff';

const endorsementDiff = (a, b) => {
  const diff = jsonDiff.diff(a, b);
  const result = [];

  Object.entries(diff).forEach(([key, value]) => {
    if (value === undefined) return;
    // buildings or trucks
    if (Array.isArray(value)) {
      value.forEach(([operation, changes], index) => {
        if (operation === '+') {
          result.push({
            op: 'add',
            path: `${key}.${index}`,
            value: changes,
          });
        }
        if (operation === '-') {
          result.push({
            op: 'remove',
            path: `${key}.${index}`,
          });
        }
        if (operation === '~') {
          Object.entries(changes).forEach(([prop, change]) => {
            if (`${change.__new}` !== `${change.__old}`) {
              result.push({
                op: 'replace',
                path: `${key}.${index}.${prop}`,
                value: change.__new?.label ?? change.__new ?? '',
              });
            }
          });
        }
      });
    } else {
      if (typeof value.__new === 'object' && `${value.__new.value}` !== `${value.__old}`) {
        result.push({
          op: 'replace',
          path: key,
          value: value.__new.label,
        });
      }
      if (typeof value.__new !== 'object') {
        result.push({
          op: 'replace',
          path: key,
          value: value?.__new ?? '',
        });
      }
    }
  });

  return result;
};

const diff = {
  endorsementDiff,
};

export default diff;
